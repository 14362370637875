import alt from 'utils/alt';
import AppActions from 'actions/app';

let modalCount = 0;

class AppStore {
  state = {
    composeMessage: false,
    messagesDropdownOpen: false,
    sideBarPinned: false,
    sidebarLeftOpened: false,
    user: false,
    platformStatus: false,
    usingProxyUser: false,
    modal: false,
    notices: [],
  };

  constructor() {
    this.bindActions(AppActions);
  }

  onSetMessageDropdownOpen = (state) => {
    if (state === this.state.messagesDropdownOpen) return this.preventDefault();
    this.setState({ messagesDropdownOpen: state });
  };

  onComposeMessage = (obj) => {
    this.setState({ messagesDropdownOpen: !!obj, composeMessage: obj });
  };

  onToggleMobileMenu = () => {
    this.setState({ sidebarLeftOpened: !this.state.sidebarLeftOpened });
  };

  onCloseMobileMenu = () => {
    if (this.state.sidebarLeftOpened) this.setState({ sidebarLeftOpened: false });
  };

  onLogin = (user) => {
    if (user !== undefined) {
      // Tell the realtime system to reinitialize if the user has changed
      if (
        typeof window === 'object' &&
        global.dsgRealtime &&
        (!this.state.user || this.state.user.hash !== user.hash)
      ) {
        global.dsgRealtime.reinitialize();
      }

      this.setState({
        user,
      });
    } else {
      // If we're clearing the user we're probably logging out and we should disconnect the realtime system
      if (typeof window === 'object' && global.dsgRealtime) {
        global.dsgRealtime.disconnect();
      }
    }
  };

  onPopModal = () => {
    const { modal } = this.state;
    if (modal && Array.isArray(modal) && modal.length > 1) {
      this.setState({ modal: modal.slice(1) });
    } else {
      this.setState({ modal: false });
    }
  };

  onSetModal = (modal) => {
    if (!modal) {
      this.setState({ modal });
    } else {
      modalCount++;
      modal.uniqueId = modalCount;
      this.setState({
        modal: !!this.state.modal
          ? Array.isArray(this.state.modal)
            ? // Prepend to the active modals
              [modal, ...this.state.modal]
            : // Turn the current single modal into a list and prepend
              [modal, this.state.modal]
          : modal,
      });
    }
  };

  onShowNotice = (notice) => {
    const runTimer = () => {
      if (this._noticeTimer) clearTimeout(this._noticeTimer);
      this._noticeTimer = setTimeout(() => {
        this.onClearNotice();
        if (this.state.notices.length) {
          runTimer();
        } else {
          this._noticeTimer = null;
        }
      }, 7000);
    };
    this.setState({
      notices: [...this.state.notices, notice],
    });
    if (!this._noticeTimer) {
      runTimer();
    }
  };

  onClearNotice = () => {
    if (this.state.notices.length) {
      this.state.notices[0].onClose && this.state.notices[0].onClose(true);
    }
    this.setState({
      notices: this.state.notices.filter((n, idx) => idx !== 0),
    });
  };

  onLogout = () => {
    this.setState({
      user: false,
      usingProxyUser: false,
    });

    // Tell the realtime system to disconnect
    if (typeof window === 'object' && global.dsgRealtime) {
      global.dsgRealtime.disconnect();
    }
  };

  onLoginProxy = (user) => {
    //console.log('onLoginProxy', user);
    if (user) {
      this.setState({ usingProxyUser: true });
    } else {
      // If we're clearing the user we're probably logging out and we should disconnect the realtime system
      if (typeof window === 'object' && global.dsgRealtime) {
        global.dsgRealtime.disconnect();
      }
    }
  };

  onLogoutOfProxy = () => {
    this.setState({ usingProxyUser: false });

    // Tell the realtime system to disconnect
    if (typeof window === 'object' && global.dsgRealtime) {
      global.dsgRealtime.disconnect();
    }
  };

  onUpdateUser = (newValues) => {
    if (newValues !== undefined && this.state.user) {
      this.setState({
        user: { ...this.state.user, ...newValues },
      });
    }
  };

  onUpdateUserRelation = (rel) => {
    const { relations = [] } = this.state.user;
    const updatedRelations = relations.map((r) => (r.id === rel.id ? rel : r));
    this.onUpdateUser({ relations: updatedRelations });
  };

  onRemoveUserRelation = (rel) => {
    const { relations = [] } = this.state.user;
    this.onUpdateUser({ relations: relations.filter((r) => r !== rel) });
  };

  onAddUserRelation = (rel) => {
    const { relations = [] } = this.state.user;
    this.onUpdateUser({ relations: [...relations, rel] });
  };
}

export default alt.createStore(AppStore, 'AppStore');
