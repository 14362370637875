import alt from '../utils/alt';

class UserManagementActions {
  constructor() {
    this.generateActions(
      'nextUsers',
      'prevUsers',
      'searched',
      'changeUser',
      'removeEndorsement',
      'removeUserByHash',
    );
  }
}

export default alt.createActions(UserManagementActions);
