import alt from 'utils/alt';
import MessageActions from 'actions/messages';

class MessageStore {
  constructor() {
    this.bindActions(MessageActions);
    this.notices = [];
    this.threads = [];
    this.thread = {};
    this.activeThreadId = null;
    this.needsRefresh = false;

    this.unreadNoticeCount = 0;
    this.update = 0;
  }

  onAddReply(message) {
    this.thread.messages = [...(this.thread.messages || []), message];
  }

  onAddServerReply(packet) {
    const { message, author } = packet;
    let foundThread = false;

    // Add replies if we're looking at the message thread
    if (this.activeThreadId === message.threadId) {
      this.thread.messages = [...(this.thread.messages || []), message];
    }

    // If there's no threadId it's because it's a group reply, mark as needing refresh
    if (!message.threadId) {
      this.needsRefresh = true;
    } else {
      // Go through any visible threads, if one matches our reply's thread then update its last message
      this.threads = (this.threads || []).map((t) => {
        // Incoming replies for groups need to trigger refresh so message breaks out into multiple threads
        if (t.numRecipients > 1) {
          this.needsRefresh = true;
          return t;
        }
        if (t.id === message.threadId) {
          foundThread = true;
          return {
            ...t,
            lastMessageId: message.id,
            lastMessage: message.body,
            lastCreated: new Date(),
            lastAuthor: author,
            unreadMessages: true,
          };
        } else {
          return t;
        }
      });

      // If we couldn't find the thread in the inbox that the reply belongs to, activate refresh indicator
      if (!foundThread) this.needsRefresh = true;
    }
  }

  onNeedsRefresh(nr) {
    this.setState({ needsRefresh: nr });
  }

  onUpdateRecipients(value) {
    this.recipients = value;
  }

  onUpdateInbox(threads) {
    this.threads = threads || [];
    this.needsRefresh = false;
  }

  onAppendInbox(threads) {
    this.threads = [...this.threads, ...threads];
  }

  onUpdateNotices(notices = []) {
    this.notices = notices;
    this.unreadNoticeCount = notices.filter((n) => !n.seen).length;
  }

  onAddUnreadNotice() {
    this.unreadNoticeCount = this.unreadNoticeCount + 1;
  }

  onResetUnreadNotice() {
    this.unreadNoticeCount = 0;
  }

  onRemoveThreads(ids) {
    // Filter out threads with an id that exists in the remove list (ids)
    this.threads = this.threads.filter((t) => !ids.includes(t.id));
  }
}

export default alt.createStore(MessageStore, 'MessageStore');
