import alt from '../utils/alt';

class FormActions {
  constructor() {
    this.generateActions(
      'setForm',
      'setActiveElement',
      'addHistory',
      'newHistory',
      'undo',
      'redo',
      'toggleExpert',
      'setToolsTab',
    );
  }
}

export default alt.createActions(FormActions);
