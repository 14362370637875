import alt from '../utils/alt';

class MessageActions {
  constructor() {
    this.generateActions(
      'addReply',
      'addServerReply',
      'needsRefresh',
      'updateRecipients',
      'updateInbox',
      'appendInbox',
      'updateUnread',
      'removeThreads',
      'updateNotices',
      'updateThread',
      'addUnreadNotice',
      'resetUnreadNotice',
    );
  }
}

export default alt.createActions(MessageActions);
