import alt from 'utils/alt';
import UserDashboardActions from 'actions/userDashboard';

class UserDashboardStore {
  constructor() {
    this.bindActions(UserDashboardActions);

    this.state = {
      volunteerism: [],
      testimonials: [],
      profile: {},
      ticker: {},
    };
  }

  onUpdateApplication = (application) => {
    this.setState({
      volunteerism: this.state.volunteerism.map((curApp) => {
        if (curApp.id === application.id) {
          return application;
        } else {
          return curApp;
        }
      }),
    });
  };

  onDeleteApplicationId = (id) => {
    this.setState({ volunteerism: this.state.volunteerism.filter((val) => val.id !== id) });
  };
}

export default alt.createStore(UserDashboardStore, 'UserDashboardStore');
