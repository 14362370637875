import alt from 'utils/alt';
import SocialFeedActions from 'actions/socialFeed';

class SocialFeedStore {
  constructor() {
    this.bindActions(SocialFeedActions);
    this.state = {
      contentFeed: [],
      recommendedPositions: [],
      hasMore: false,
      loadAmount: 10,
      focusCity: null,
      collapseCities: null,
    };
  }

  onNewFeedContent = (content = []) => {
    this.setState({ contentFeed: content, hasMore: content.length >= this.state.loadAmount });
  };

  // For single posts
  onPrependContent = (content) => {
    const hasMore =
      1 + this.state.contentFeed.length - this.state.recommendedPositions.length <
      this.state.loadAmount
        ? false
        : this.state.hasMore;
    this.setState({ contentFeed: [content, ...this.state.contentFeed], hasMore });
  };

  onAppendContent = (content) => {
    if (content.length < this.state.loadAmount) this.setState({ hasMore: false });
    this.setState({
      contentFeed: [...this.state.contentFeed, ...content],
    });
  };

  onRemovePost = (post) => {
    this.setState({
      contentFeed: this.state.contentFeed.filter((curPost) => curPost.id !== post.id),
    });
  };

  onRemoveRecommendation = (r) => {
    // Clear the copy in the master recommendations list
    // Clear the copy in the contentFeed
    this.setState({
      recommendedPositions: this.state.recommendedPositions.filter(
        (c) => c.recommendationId !== r.recommendationId,
      ),
      contentFeed: this.state.contentFeed.filter((c) => c.id !== `rec-${r.recommendationId}`),
    });
  };

  onUpdatePost = (post) => {
    this.setState({
      contentFeed: this.state.contentFeed.map((curPost) => {
        if (curPost.id === post.id) {
          return post;
        } else {
          return curPost;
        }
      }),
    });
  };
}

export default alt.createStore(SocialFeedStore, 'SocialFeedStore');
