import alt from 'utils/alt';
import BusinessDashboardActions from 'actions/businessDashboard';

class BusinessDashboardStore {
  constructor() {
    this.bindActions(BusinessDashboardActions);

    this.state = {
      overview: false,
      managementOverview: {},
      managementStats: {},
      error: false,
      statusCode: 200,
    };
  }

  onFetchedOverview = (overview = {}) => {
    this.setState({ overview });
  };

  onDecreaseCount = (data) => {
    const { key, amount } = data;
    const managementOverview = {
      ...this.state.managementOverview,
      [key]: this.state.managementOverview[key] - amount,
    };
    this.setState({ managementOverview });
  };

  onUpdateManagementOverview = (updates) => {
    this.setState({ managementOverview: { ...this.state.managementOverview, ...updates } });
  };
}

export default alt.createStore(BusinessDashboardStore, 'BusinessDashboardStore');
