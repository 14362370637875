import alt from 'utils/alt';
import HourActions from 'actions/hour';
import ReactUpdate from 'react-addons-update';

class HourStore {
  constructor() {
    this.bindActions(HourActions);
    this.statusCode = 200;
    this.hours = [];
    this.hoursInReview = [];
  }

  onRemoveHourIdsFromStore(obj) {
    const { idArray, storeKey } = obj;
    const remainingUnapprovedHours = this[storeKey].filter((hour) => !idArray.includes(hour.id));
    this[storeKey] = remainingUnapprovedHours;
  }

  onUpdateHourUser(user) {
    this.hours = (this.hours || []).map((curHour) => {
      if (curHour.user.hash === user.hash) {
        return ReactUpdate(curHour, { $merge: { user } });
      } else {
        return curHour;
      }
    });
    this.hoursInReview = (this.hoursInReview || []).map((curHour) => {
      if (curHour.user.hash === user.hash) {
        return ReactUpdate(curHour, { $merge: { user } });
      } else {
        return curHour;
      }
    });
  }
}

export default alt.createStore(HourStore, 'HourStore');
