'use strict';

import alt from '../utils/alt';

class AppActions {
  constructor() {
    this.generateActions(
      'fileDrag',
      'droppedFile',
      'setIsXhrActive',
      'addJoyrideSteps',
      'clearJoyrideSteps',
    );
  }
}

export default alt.createActions(AppActions);
