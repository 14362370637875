import alt from '../utils/alt';

class OrganizationDashboardActions {
  constructor() {
    this.generateActions(
      'setStartDate',
      'setEndDate',
      'fetchedOverview',
      'decreaseCount',
      'updateManagementOverview',
    );
  }
}

export default alt.createActions(OrganizationDashboardActions);
