import alt from '../utils/alt';

class OpportunityActions {
  constructor() {
    this.generateActions(
      'newOpportunityFeedContent',
      'appendOpportunityFeedContent',
      'updateEvent',
    );
  }
}

export default alt.createActions(OpportunityActions);
