import alt from 'utils/alt';
import OrganizationDashboardActions from 'actions/organizationDashboard';

class OrganizationDashboardStore {
  constructor() {
    this.bindActions(OrganizationDashboardActions);

    this.state = {
      overview: false,
      managementOverview: {},
      managementStats: {},
      volunteers: [],
      error: false,
    };
  }

  onFetchedOverview = (overview = {}) => {
    this.setState({ overview });
  };

  onDecreaseCount = (data) => {
    const { key, amount } = data;
    const managementOverview = {
      ...this.state.managementOverview,
      [key]: this.state.managementOverview[key] - amount,
    };
    this.setState({ managementOverview });
  };

  onUpdateManagementOverview = (updates) => {
    this.setState({ managementOverview: { ...this.state.managementOverview, ...updates } });
  };
}

export default alt.createStore(OrganizationDashboardStore, 'OrganizationDashboardStore');
