import alt from '../utils/alt';
import config from '../../config';

import TalkToTeamModal from '../components/modals/TalkToTeam';
import StartTrialModal from '../components/modals/StartTrial';

class AppActions {
  constructor() {
    this.generateActions(
      'logout',
      'login',
      'logoutOfProxy',
      'loginProxy',
      'toggleMobileMenu',
      'closeMobileMenu',
      'setModal',
      'popModal',
      'showNotice',
      'clearNotice',
      'updateUser',
      'updateUserRelation',
      'removeUserRelation',
      'addUserRelation',
      'composeMessage',
      'setMessageDropdownOpen',
    );
  }

  showUpgradeModal(context) {
    this.setModal({
      component: TalkToTeamModal,
      options: { context },
    });
  }

  showTrialModal(context, location) {
    this.setModal({
      component: StartTrialModal,
      options: { context, location },
    });
  }
}

const actions = alt.createActions(AppActions);

actions.changePageTitle = function (title, override) {
  if (typeof window === 'object') {
    window.document.title = title + (!override ? ` - ${config.company.name}` : '');
    //analytics.trackEvent(AnalyticsTypes.VIEW, { type: AnalyticsContexts.PAGE }, title);
  }
};

export default actions;
