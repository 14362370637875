import alt from 'utils/alt';
import BusinessActions from 'actions/business';

class BusinessStore {
  constructor() {
    this.bindActions(BusinessActions);

    this.state = {
      businesses: {},
      businessList: [],
      business: null,
      affiliates: [],
      testimonials: [],
      volunteerism: [],
      supportedCauses: [],
      statusCode: 200,
      offset: 0,
      limit: 100,
      totalBus: 0,
      offer: null,
      businessRollupDonations: 0,
      businessRollupHours: 0,
      dedicatedTestimonialView: false,
      dedicatedTestimonialId: 0,
    };
  }

  onNext = (body) => {
    if (body.results.length) {
      this.setState({
        businessList: body.results,
        offset: this.state.offset + this.state.limit,
        totalBus: body.totalBus,
      });
    }
  };

  onPrev = (body) => {
    const offset = this.state.offset - this.state.limit;
    this.setState({
      businessList: body.results,
      offset: offset < 0 ? 0 : offset,
      totalBus: body.totalBus,
    });
  };

  onUpdateTestimonials = (testimonials) => {
    this.setState({ testimonials });
  };
}

export default alt.createStore(BusinessStore, 'BusinessStore');
