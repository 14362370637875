import alt from 'utils/alt';
import OrganizationActions from 'actions/organization';

class OrganizationStore {
  constructor() {
    this.bindActions(OrganizationActions);
    // Staff area only uses this
    this.organizations = {};

    // Community page
    this.organizationList = [];

    // Active org
    this.organization = null;

    // Listings
    this.offset = 0;
    this.limit = 100;
    this.totalOrgs = 0;

    this.affiliates = [];
    this.testimonials = [];
    this.businesses = [];
  }

  onNextOrgs(body) {
    if (body.results.length) {
      this.organizationList = body.results;
      this.offset += this.limit;
    }
  }

  onPrevOrgs(body) {
    this.organizationList = body.results;
    this.offset -= this.limit;
    if (this.offset < 0) this.offset = 0;
    this.totalOrgs = body.totalOrgs;
  }

  onSearched(body) {
    this.organizationList = body.results;
    this.offset = 0;
    this.totalOrgs = body.totalOrgs;
  }

  onUpdateInList(organization) {
    this.organizationList = this.organizationList.map((org) =>
      org.slug === organization.slug ? organization : org,
    );
  }
}

export default alt.createStore(OrganizationStore, 'OrganizationStore');
