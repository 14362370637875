import alt from 'utils/alt';
import UserManagementActions from 'actions/userManagement';

class UserManagementStore {
  constructor() {
    this.bindActions(UserManagementActions);
    this.users = [];
    this.endorsements = [];
    this.offset = 0;
    this.limit = 25;
    this.totalUsers = 0;
  }

  onNextUsers(body) {
    if (body.users.length) {
      this.users = body.users;
      this.offset += this.limit;
    }
  }

  onPrevUsers(body) {
    this.users = body.users;
    this.offset -= this.limit;
    if (this.offset < 0) this.offset = 0;
    this.totalUsers = body.totalUsers;
  }

  onSearched(body) {
    this.users = body.users;
    this.offset = 0;
    this.totalUsers = body.totalUsers;
  }

  onRemoveUserByHash(user) {
    this.users = this.users.filter((curUser) => {
      return curUser.hash !== user.hash;
    });
    this.totalUsers -= 1;
  }

  onRemoveEndorsement(endorsement) {
    this.endorsements = this.endorsements.filter((cur) => {
      return cur.applicationSlug !== endorsement.applicationSlug;
    });
  }

  onChangeUser(user) {
    this.users = (this.users || []).map((curUser) => {
      if (curUser.relationshipId === user.relationshipId) {
        return user;
      } else {
        return curUser;
      }
    });
    this.endorsements = (this.endorsements || []).map((curUser) => {
      if (curUser.relationshipId === user.relationshipId) {
        return user;
      } else {
        return curUser;
      }
    });
  }
}

export default alt.createStore(UserManagementStore, 'UserManagementStore');
