import alt from 'utils/alt';
import OpportunityActions from 'actions/opportunity';

class OpportunityStore {
  constructor() {
    this.bindActions(OpportunityActions);

    this.state = {
      statusCode: 200,
      opportunityFeed: [],
      hasMoreOpportunities: true,
      loadAmount: 8,
      fromServer: false,
    };
  }

  onNewOpportunityFeedContent = (content) => {
    this.setState({
      hasMoreOpportunities: !(content.length < this.state.loadAmount),
      opportunityFeed: content || [],
      fromServer: false,
    });
  };

  onAppendOpportunityFeedContent = (content) => {
    this.setState({
      hasMoreOpportunities: !(content.length < this.state.loadAmount),
      opportunityFeed: [...this.state.opportunityFeed, ...content],
      fromServer: false,
    });
  };

  onUpdateEvent = (event) => {
    this.setState({ event });
  };
}

export default alt.createStore(OpportunityStore, 'OpportunityStore');
