import alt from '../utils/alt';

class SocialFeedActions {
  constructor() {
    this.generateActions(
      'newFeedContent',
      'prependContent',
      'appendContent',
      'setLoading',
      'removePost',
      'updatePost',
      'removeRecommendation',
    );
  }
}

export default alt.createActions(SocialFeedActions);
