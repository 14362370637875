import alt from 'utils/alt';
import NotificationActions from 'actions/notification';

class NotificationStore {
  constructor() {
    this.bindActions(NotificationActions);
    this.unreadCount = 0;
    this.unreadNoticeCount = 0;
    this.unreadManagementCount = 0;
    this.unreadManagement = [];
    this.update = 0;
  }

  onUpdateUnread(count) {
    if (count !== undefined) {
      this.unreadCount = count;
    } else {
      this.update += 1;
      this.unreadCount += 1;
    }
  }
}

export default alt.createStore(NotificationStore, 'NotificationStore');
