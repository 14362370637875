import alt from 'utils/alt';
import AppStatusActions from 'actions/appStatus';

class AppStatusStore {
  constructor() {
    this.bindActions(AppStatusActions);

    this.state = {
      isXhrActive: false,
      dragCount: 0,
      showDropzone: false,
      joyrideSteps: [],
    };
  }

  onSetIsXhrActive = (state) => {
    if (state !== this.state.isXhrActive) this.setState({ isXhrActive: state });
  };

  onFileDrag = (e) => {
    if (e.type === 'dragenter') {
      if (!this.state.showDropzone) this.setState({ showDropzone: true });
      this.setState({ dragCount: this.state.dragCount + 1 });
    }
    if (e.type === 'dragleave') {
      const dragCount = this.state.dragCount - 1;
      this.setState({ dragCount });
      if (dragCount <= 0) {
        this.setState({ dragCount: 0 });
        if (this.state.showDropzone) this.setState({ showDropzone: false });
      }
    }
  };

  onDroppedFile = () => {
    const dragCount = this.state.dragCount - 1;
    this.setState({ dragCount });
    if (dragCount <= 0) {
      this.setState({ dragCount: 0, showDropzone: false });
    }
  };

  onAddJoyrideSteps = (steps) => {
    if (!Array.isArray(steps)) steps = [steps];
    if (!steps.length) return false;
    this.setState({ joyrideSteps: [...this.state.joyrideSteps, ...steps] });
  };

  onClearJoyrideSteps = () => {
    this.setState({ joyrideSteps: [] });
  };
}

export default alt.createStore(AppStatusStore, 'AppStatusStore');
