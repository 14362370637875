import alt from 'utils/alt';
import InviteActions from 'actions/invite';

class InviteStore {
  constructor() {
    this.bindActions(InviteActions);
    this.state = {
      organization: false,
      token: null,
      invite: false,
      inviteMode: 'unknown',
      inviteToken: false,
      inviteUrl: false,
      inviteActivated: false,
      inviteAcceptError: false
    };
  }

  onSetInvite = invite => {
    this.setState({ invite });
  };

  onReset = () => {
    this.setState({ invite: false, token: null });
  };
}

export default alt.createStore(InviteStore, 'InviteStore');
