import alt from 'utils/alt';
import FormActions from 'actions/form';

class FormStore {
  constructor() {
    this.bindActions(FormActions);
    this.state = {
      form: null,
      statusCode: 200,
      activeElement: null,
      history: [],
      historyIndex: -1,
      expert: false,
      toolsTab: 'prefabs',
    };
  }

  onSetToolsTab = (toolsTab) => {
    this.setState({ toolsTab });
  };

  onSetForm = (form) => {
    this.setState({ form });
  };

  onSetActiveElement = (element) => {
    this.setState({ activeElement: element });
  };

  onToggleExpert = () => {
    this.setState({ expert: !this.state.expert });
  };

  onUndo = () => {
    if (this.state.history.length >= this.state.historyIndex + 1) {
      const newIdx = this.state.historyIndex + 1;
      this.setState({
        historyIndex: newIdx,
        form: this.state.history[newIdx],
      });
    }
  };

  onRedo = () => {
    if (this.state.historyIndex > 0) {
      const newIdx = this.state.historyIndex - 1;
      this.setState({ historyIndex: newIdx, form: this.state.history[newIdx] });
    }
  };

  onNewHistory = (form) => {
    this.setState({ history: [form], historyIndex: 0 });
  };

  onAddHistory = (form) => {
    if (!this.state.history.length || form !== this.state.history[this.state.historyIndex]) {
      if (this.state.historyIndex === 0) {
        this.setState({ history: [form, ...this.state.history].slice(0, 50) });
      } else {
        /*console.log(
          'history',
          [form, ...this.state.history.slice(this.state.historyIndex, 50)].slice(0, 50),
          'historyIndex',
          0
        );*/
        this.setState({
          history: [form, ...this.state.history.slice(this.state.historyIndex, 50)].slice(0, 50),
          historyIndex: 0,
        });
      }
    } else {
      /*this.setState({
        historyIndex: this.state.historyIndex > 0 ? this.state.historyIndex - 1 : 0,
      });*/
    }
  };
}

export default alt.createStore(FormStore, 'FormStore');
